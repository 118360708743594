<template>
  <b-card
    id="display-draw-note-card"
    no-body
    class="component-display-draw-note"
    border-variant="primary"
  >
    <div v-show="!isItemSelected" class="text-center text-muted m-3">
      กรุณาเลือกรายการรักษา
    </div>
    <div v-show="isItemSelected">
      <b-collapse v-model="hideStage">
        <div class="float-left m-3">
          <span v-if="lines.length === 0" class="text-muted"
            >ไม่มีบันทึกการเขียน</span
          >
          <span v-else class="text-info">
            มีบันทึกการเขียน กรุณาขยายเพื่อดูข้อมูล
            <i class="fas fa-arrow-right"></i>
          </span>
        </div>

        <b-button
          class="float-right m-2"
          variant="outline-primary"
          size="sm"
          @click="toggleCollapse"
        >
          <i class="fas fa-angle-double-down" />
        </b-button>
      </b-collapse>
      <!-- <b-collapse v-model="visible" @shown="setupStage"> -->
      <b-collapse v-model="visible">
        <div class="tl-absolute">
          <span class="description p-1 mr-2 bg-info rounded"
            >ใช้สองนิ้วเพื่อลาก และซูม</span
          >

          <!-- <b-button
            class="mr-2"
            variant="outline-primary"
            size="sm"
            :disabled="btnDisabled"
            @click="openDrawingTxHxModal"
          >
            <i class="fas fa-edit"></i>
          </b-button> -->
          <b-button
            class
            variant="outline-primary"
            size="sm"
            @click="toggleCollapse"
          >
            <i class="fas fa-angle-double-up" />
          </b-button>
        </div>

        <div class="overflow-hidden"
          v-for="(page, index) in pages"
          :key="index"
        >           
          <v-stage
            :id="`display-draw-note-stage${index}`"
            :ref="`stage${index}`"
            :config="configKonva"
            @touchmove="startMove"
            @touchend="endMove"
          >
            <!-- <v-layer ref="background"> </v-layer> -->
            <v-layer>
              <v-rect :config="page.configBackground"></v-rect>
              <v-text :config="page.configText"></v-text>
              <v-text
                :config="{
                  x: page.configBackground.width / 2,
                  y: page.configBackground.height / 2,
                  text: index + 1, // Big page number
                  fontSize: 200, // Large font size for watermark
                  fontFamily: 'Arial',
                  fill: 'rgba(0, 0, 0, 0.05)', // Faded color
                  align: 'center',
                  verticalAlign: 'middle',
                  offsetX: 100,
                  offsetY: 100,
                }"
              ></v-text>
              <v-line v-for="(line, lineindex) in lines[index]" 
                :key="`line-${index}-${lineindex}`" :config="line"></v-line>
              <!-- <v-rect :config="configForeground"></v-rect> -->
            </v-layer>
          </v-stage>
          <br>
        </div> 

      </b-collapse>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import Vue from "vue";
// import VueKonva from "vue-konva";
// Vue.use(VueKonva);

import moment from "moment";
import { eventBus } from "@/main";

import noteTemplate from "@/assets/img/noteTemplate.jpeg";
// import { preventDefault } from "@fullcalendar/core/util/dom-event";

export default {
  name: "DisplayDrawNote",

  components: {},

  props: ["drawNote", "selectedItem"],
  data() {
    return {
      configKonva: {
        width: 720,
        height: 960,
      },

      configBackground: {
        x: 0,
        y: 0,
        width: 720,
        height: 960,
        fillPatternImage: null,
        preventDefault: false,
      },
      configText: {
        x: 15,
        y: 10,
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
        text: this.drawNote?.text || "ไม่มึข้อมูลบันทึก",
      },
      // configForeground: {
      //   x: 0,
      //   y: 0,
      //   width: 720,
      //   height: 960,
      // },
      lines: this.drawNote?.lines || [],
      pages: [],

      image: null,

      //Konva objects
      stage: null,

      lastCenter: null,
      lastDist: 0,
      visible: true,
      drawNoteInitialized: false,
    };
  },
  watch: {
    // selectedItem(newVal) {
    //   console.log("Prop selectedItem updated:", newVal);
    //   console.log("isItemSelected:", this.isItemSelected);
    // }
  },
  created() {},
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      isDoctor: "moduleUser/isDoctor",
    }),
    isItemSelected() {
      return this.selectedItem?.treatId || this.selectedItem?.id;
    },
    btnDisabled() {
      return !(
        (
          this.selectedItem?.treatId &&
          this.isDoctor &&
          moment().diff(this.selectedItem.creationDt, "days") <= 2
        )
        // &&  this.selectedItem?.items
        //   .map(i => i.actionUid)
        //   .includes(this.userInfo.uid)
      );
    },
    hideStage: {
      get() {
        return !this.visible;
      },
      set(val) {},
    },
  },
  methods: {
    //zoom
    getDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    },
    getCenter(p1, p2) {
      return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
      };
    },

    startMove(e) {
      var touch1 = e.evt.touches[0];
      var touch2 = e.evt.touches[1];

      if (touch1 && touch2) {
        e.evt.preventDefault();

        // if the stage was under Konva's drag&drop
        // we need to stop it, and implement our own pan logic with two pointers
        if (this.stage.isDragging()) {
          this.stage.stopDrag();
        }

        var p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        var p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!this.lastCenter) {
          this.lastCenter = this.getCenter(p1, p2);
          return;
        }
        var newCenter = this.getCenter(p1, p2);

        var dist = this.getDistance(p1, p2);

        if (!this.lastDist) {
          this.lastDist = dist;
        }

        // local coordinates of center point
        var pointTo = {
          x: (newCenter.x - this.stage.x()) / this.stage.scaleX(),
          y: (newCenter.y - this.stage.y()) / this.stage.scaleX(),
        };

        var scale = (this.stage.scaleX() * (dist / this.lastDist)) / 1.0001;

        this.stage.scaleX(scale);
        this.stage.scaleY(scale);

        // calculate new position of the stage
        var dx = newCenter.x - this.lastCenter.x;
        var dy = newCenter.y - this.lastCenter.y;

        var newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        this.stage.position(newPos);
        this.stage.batchDraw();

        this.lastDist = dist;
        this.lastCenter = newCenter;
      }
    },
    endMove(e) {
      this.lastDist = 0;
      this.lastCenter = null;
    },

    // handleWheel(e) {
    //   e.evt.preventDefault();
    //   var scaleBy = 1.1;
    //   var oldScale = this.stage.scaleX();
    //   var pointer = this.stage.getPointerPosition();
    //   var mousePointTo = {
    //     x: (pointer.x - this.stage.x()) / oldScale,
    //     y: (pointer.y - this.stage.y()) / oldScale,
    //   };
    //   var newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    //   this.stage.scale({ x: newScale, y: newScale });
    //   var newPos = {
    //     x: pointer.x - mousePointTo.x * newScale,
    //     y: pointer.y - mousePointTo.y * newScale,
    //   };
    //   this.stage.position(newPos);
    //   this.stage.batchDraw();
    // },

    //export file
    exportToPNG() {
      if (this.lines.length === 0) {
        this.$refs.Dialog.showAlertInfo("ไม่มีข้อมูลให้บันทึก");
        return;
      } else {
        let dataURL = this.stage.toDataURL({
          mimeType: "image/png",
          pixelRatio: 2,
        });
        this.downloadURI(dataURL, "EMR.png");
      }
    },
    downloadURI(uri, name) {
      let link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    defaultValue() {
      this.lines = [];
      this.configText = {
        x: 15,
        y: 10,
        text: "",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      };
      this.selectedItem = {};
      this.aptDraftDisplay = {};

      this.stage.position({ x: 0, y: 0 });
      this.stage.scaleX(1);
      this.stage.scaleY(1);
    },
    // async resetDrawScale() {
    //   await this.$nextTick();
    //   await this.$nextTick();
    //   if (!this.stage.content?.offsetParent?.offsetWidth) return;
    //   this.stage.position({ x: 0, y: 0 });
    //   this.stage.scaleX(1);
    //   let scale =
    //     this.stage.scaleX() *
    //     (this.stage.content.offsetParent.offsetWidth / 720);
    //   this.stage.scaleX(scale);
    //   this.stage.scaleY(scale);
    //   this.stage.batchDraw();
    //   document.getElementById("display-draw-note-stage").style.height = `${
    //     960 * scale
    //   }px`;
    // },


    // async resetDrawScale() {
    //   // Ensure DOM updates are applied before proceeding
    //   await this.$nextTick();
    //   await this.$nextTick();

    //   // Determine the number of pages based on the length of lines
    //   const savedPagesCount = Array.isArray(this.lines) && this.lines.length > 0 ? this.lines.length : 1;
    //   console.log("savedPagesCount", savedPagesCount)
    //   console.log("lines in reset drawscale", this.lines)
    //   this.pages = []

    //   // Populate the pages dynamically
    //   this.pages = Array.from({ length: savedPagesCount }, (_, index) => ({
    //     configBackground: {
    //       x: 0,
    //       y: 0,
    //       width: 720,
    //       height: 960,
    //       fillPatternImage: null,
    //       preventDefault: false,
    //     },
    //     configText: {
    //       x: 15,
    //       y: 10,
    //       fontSize: 14,
    //       lineHeight: 1.5,
    //       fill: "#3D556E",
    //       // text: this.configText?.text || (this.lines[index]?.text || "ไม่มีข้อมูล"), // Use line's text or default text
    //       text: "ไม่มีข้อมูล"
    //     },
    //     // lines: this.lines[index] || [],
    //     lines: []
    //   }));

    //   // Wait for the pages to render
    //   await this.$nextTick();
    //   console.log("this.$refs", this.$refs)

    //   for (let i = 0; i < savedPagesCount; i++) {
    //     const stageRefKey = `stage${i}`
    //     const stageRef = this.$refs[stageRefKey]?.[0]
    //     const stage = stageRef.getNode()

    //     if (stage.content?.offsetParent?.offsetWidth) {

    //       const image = new window.Image()
    //       image.src = noteTemplate

    //       image.onload = () => {
    //         this.pages[i].configBackground.fillPatternImage = image

    //         stage.draw()

    //         // Reset position and scale for the stage
    //         stage.position({ x: 0, y: 0 });

            
    //         stage.scaleX(1);
    //         stage.scaleY(1);

    //         // const containerWidth = stage.content.offsetParent.offsetWidth;
    //         // const scale = containerWidth / 720;
    //         // const canvasAspectRatio = 720 / 960;

    //         // Calculate the new scale based on the container's width
    //         const scale =
    //           stage.scaleX() *
    //           (stage.content.offsetParent.offsetWidth / 720);
    //         stage.scaleX(scale);
    //         stage.scaleY(scale);
    //         stage.batchDraw();
    //         console.log("reset scale")
    //         console.log("scale", scale)
    //         console.log("offset width",stage.content?.offsetParent?.offsetWidth)
    //         console.log("Scale applied:", scale);

    //         // Dynamically update the height of the stage container
    //         const stageElementId = `display-draw-note-stage${i}`;
    //         const stageElement = document.getElementById(stageElementId);
    //         if (stageElement) {
    //           stageElement.style.height = `${900 * scale}px`;
    //           console.log("styleElement.style.height", stageElement.style.height)
    //           // stageElement.style.height = `{containerWidth / canvasAspectRatio}px`;
    //         }
    //       }
          
    //     }else {
    //       console.warn(`Stage reference ${stageRefKey} is invalid or its container is not visible.`);
    //     }
    //   }      
    // },

    assignDrawNote(e) {
      if (e?.lines.length > 0) {
        this.configText.text = e.text || "";
        this.lines = [...e.lines];
        console.log("In assignDrawNote", this.configText.text)
      } else {
        this.configText.text = "ไม่มีข้อมูล";
        this.lines = [];
      }

      // this.stage.batchDraw()
      // if (this.$refs) {
      //   Object.keys(this.$refs).forEach(refName => {
      //     if (refName.startsWith("stage")) {
      //       const stageWrapper = this.$refs[refName]?.[0]
      //       if (stageWrapper) {
      //         console.log("Inside assignDrawNote stage:", refName)

      //         const stageRef = stageWrapper.getNode()
      //         if (stageRef) {
      //           stageRef.batchDraw()
      //         } else {
      //           console.warn(`Could not get Konva stage for ref: ${refName}`)
      //         }
      //       } else {
      //         console.warn(`No stage wrapper found for ref: ${refName}`)
      //       }
            
      //     }
      //   });
      // }
    
      this.pages = []
      this.initializePages()
       
    },
    // openDrawingTxHxModal() {
    //   this.$parent.openDrawingTxHxModal();
    // },
    async setupStage() {
      await this.$nextTick();
      this.resetDrawScale();
    },
    toggleCollapse() {
      this.visible = !this.visible;
      localStorage.setItem("displayDrawNote", this.visible);
    },

    async initializePages() {
      console.log("Called from assignDrawNote")
      const savedPagesCount = Array.isArray(this.lines) && this.lines.length > 0 ? this.lines.length : 0
      // const savedPagesCount = this.drawNote.lines && this.drawNote.lines.length > 0 ? this.drawNote.lines.length : 0;
      // console.log("Lines length", this.lines)
      if (savedPagesCount === 0) {
        this.pages.push({
          configBackground: {
            x: 0,
            y: 0,
            width: 720,
            height: 960,
            fillPatternImage: null,
            preventDefault: false,
          },
          configText: {
            x: 15,
            y: 10,
            fontSize: 14,
            lineHeight: 1.5,
            fill: "#3D556E",
            text: this.configText?.text || "ไม่มึข้อมูลบันทึก",
          },
          lines: []
        })
        // console.log("In save in displaydrawNote")
        // console.log("Lines in save", this.lines)
        // console.log("Number of pages", this.pages.length)
        const numPages = 1
        console.log("inside initializePages")
        this.$nextTick(() => {
          for (let i = 0; i < numPages; i++) {
            const stageRefKey = `stage${i}`
            const stageRef = this.$refs[stageRefKey]?.[0]

            if (stageRef) {
              const stage = stageRef.getNode()

              const image = new window.Image()
              image.src = noteTemplate

              image.onload = () => {
                this.pages[i].configBackground.fillPatternImage = image
                

                stage.draw()
              }
              image.onerror = () => {
                console.error("Failed to load image:", image.src)
              }
            }
          }
        })
      } else {
        this.pages = Array.from({ length: savedPagesCount }, (_, i) => ({
          configBackground: {
            x: 0,
            y: 0,
            width: 720,
            height: 960,
            fillPatternImage: null,
            preventDefault: false,
          },
          configText: {
            x: 15,
            y: 10,
            fontSize: 14,
            lineHeight: 1.5,
            fill: "#3D556E",
            text: this.configText?.text || "ไม่มึข้อมูลบันทึก",
          },
          lines: this.lines[i] || []
        }))
        // console.log("In else in displaydrawNote")

        const numPages = savedPagesCount
        console.log("inside initializePages")
        this.$nextTick(() => {
          for (let i = 0; i < numPages; i++) {
            const stageRefKey = `stage${i}`
            const stageRef = this.$refs[stageRefKey]?.[0]

            if (stageRef) {
              const stage = stageRef.getNode()

              const image = new window.Image()
              image.src = noteTemplate

              image.onload = () => {
                this.pages[i].configBackground.fillPatternImage = image

                stage.draw()
              }
              image.onerror = () => {
                console.error("Failed to load image:", image.src);
              };
            }else {
              console.warn(`Stage reference ${stageRefKey} not found in else initializePages`);
            }
          }
        })
      }
    }
  },

  async mounted() {
    // await this.$nextTick();
    // this.stage = this.$refs.stage.getNode();
    // // this.stage.height(960);
    // // this.stage.width(720);
    // const image = new window.Image();
    // image.src = noteTemplate;
    // image.onload = () => {
    //   this.configBackground.fillPatternImage = image;
    // };
      // console.log("lines in mounted", this.lines)
    // Initialize stages and layers dynamically.stage = this.lines.map((_, index) => this.$refs[`stage-${index}`].getNode());
    // this.stage = this.lines.map((_, index) => {
    //   const stageRef = this.$refs[`stage-${index}`];
    //   return Array.isArray(stageRef) ? stageRef[0].getNode() : stageRef.getNode(); // Handle potential array from $refs
    // });

   

    await this.$nextTick();
    localStorage.getItem("displayDrawNote") === null
      ? (this.visible = true)
      : (this.visible = localStorage.getItem("displayDrawNote") === "true");

    // this.resetDrawScale();

    // eventBus.$on("updateDrawNote", this.assignDrawNote);
    eventBus.$on("updateDrawNote", (updatedDrawNote) => {
      // console.log("Received updated drawNote:", JSON.stringify(updatedDrawNote)); // Log the updated drawNote
      this.assignDrawNote(updatedDrawNote); // Then call assignDrawNote
      // this.initializePages();

    });
    // this.selectedItem = {}
    
    console.log("Component mounted - isItemSelected:", this.isItemSelected);
    if (this.isItemSelected !== undefined && this.isItemSelected) {
      this.assignDrawNote()
    }
    

    // await this.$nextTick()
    // this.initializePages();
    // await this.initializePages();
    // console.log("From DISPLAY DRAW NOTE")
    
  },
  beforeDestroy() {
    // ลด memory leak
    // this.stage.height(1);
    // this.stage.width(1);
    // this.stage = null;
    // eventBus.$off("drawNoteUpdated")


    // multi stage
    Object.keys(this.$refs).forEach((refKey) => {
      if (refKey.startsWith("stage")) {
        const vueStage = this.$refs[refKey][0]; // Get the Vue Konva wrapper component
        if (vueStage && vueStage.getStage) {
          const stage = vueStage.getStage(); // Get the Konva Stage instance
          if (stage) {
            stage.height(1); // Minimize canvas size
            stage.width(1);
            stage.destroy(); // Destroy the stage
            eventBus.$off("updateDrawNote")
          }
        }
      }
    });
  },
  destroyed() {},
};
</script>

<style>
#display-draw-note-card {
  overflow: hidden;
}
#display-draw-note-stage {
  overflow: hidden;
}
.tl-absolute {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.page-navigation {
  position: absolute;
  bottom: 10px; /* Position the buttons near the bottom of the stage */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px; /* Add spacing between buttons and page text */
}

.page-navigation button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.page-navigation button:hover {
  background-color: #0056b3;
}

.page-navigation span {
  font-size: 14px;
  color: #3D556E;
}
</style>